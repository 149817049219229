import axios from "axios"
import { APPLICATION_SEND_URL, APPLICATION_URL, AUTH_LOGIN_URL, AUTH_REGISTER_URL, COUNTRY_GET_URL, COUNTRY_GETALL_URL, FILEGROUP_GET_URL, FILEGROUP_GETALL_URL, FILES_GET_URL, FILES_GETALL_URL, FILES_GETALLBYGROUP_URL, FOOTER_GET_URL, FOOTER_GETALL_URL, FORM_CREATE_URL, FORM_DELETE_URL, FORM_GET_URL, FORM_GETALL_URL, FORM_UPDATE_URL, FORMELEMENT_CREATE_URL, FORMELEMENT_DELETE_URL, FORMELEMENT_GET_URL, FORMELEMENT_GETALL_URL, FORMELEMENT_GETALLBYFORM_URL, FORMELEMENT_UPDATE_URL, FORMRESPONSE_CREATE_URL, FORMRESPONSE_DELETE_URL, FORMRESPONSE_GET_URL, FORMRESPONSE_GETALL_URL, FORMRESPONSE_GETALLBYFORM_URL, HEADER_CREATE_URL, HEADER_DELETE_URL, HEADER_GET_URL, HEADER_GETALL_URL, HEADER_UPDATE_URL, IMAGE_CREATE_URL, IMAGE_DELETE_URL, IMAGE_GET_URL, IMAGE_GETALL_URL, IMAGEGROUP_CREATE_URL, IMAGEGROUP_DELETE_URL, IMAGEGROUP_GET_URL, IMAGEGROUP_GETALL_URL, IMAGEGROUP_UPDATE_URL, JOB_GET_URL, JOB_GETALL_URL, JOBPAGE_GET_URL, JOBPAGE_GETALL_URL, LANGUAGE_CREATE_URL, LANGUAGE_DELETE_URL, LANGUAGE_GET_URL, LANGUAGE_GETALL_URL, LANGUAGE_UPDATE_URL, PAGE_CREATE_URL, PAGE_DELETE_URL, PAGE_GET_URL, PAGE_GETALL_URL, PAGE_GETBYURL_URL, PAGE_UPDATE_URL, PROJECT_GET_URL, PROJECT_GETALL_URL, PROJECTTYPE_GET_URL, PROJECTTYPE_GETALL_URL, SEO_CREATE_URL, SEO_DELETE_URL, SEO_GET_URL, SEO_GETALL_URL, SEO_UPDATE_URL, SERVICE_CREATE_URL, SERVICE_DELETE_URL, SERVICE_GET_URL, SERVICE_GETALL_URL, SERVICE_UPDATE_URL, SETTING_GET_URL, SETTING_GETALL_URL, SETTING_UPDATE_URL, SHOWCASE_CREATE_URL, SHOWCASE_DELETE_URL, SHOWCASE_GET_URL, SHOWCASE_GETALL_URL, SHOWCASE_GETALLBYPAGE_URL, SHOWCASE_UPDATE_URL, SOCIALMEDIA_CREATE_URL, SOCIALMEDIA_DELETE_URL, SOCIALMEDIA_GET_URL, SOCIALMEDIA_GETALL_URL, SOCIALMEDIA_UPDATE_URL, USER_CHANGEPASSWORD_URL, USER_GET_URL, USER_GETALL_URL } from '../api/api'

const auth = localStorage.getItem("auth")
const token = auth ? JSON.parse(auth).accessToken : ""
const userId = auth ? JSON.parse(auth).userId : ""
const header = { headers: { 'Authorization': `Bearer ${token}` } }
const formDataHeader = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } }


// Auth
export function login(userName, password) {
    return axios.post(AUTH_LOGIN_URL, {
        "userName": userName,
        "password": password
    }).then((response) => response.data)
}
export function register(firstName, lastName, userName, email, phoneNumber, password, roles) {
    return axios.post(AUTH_REGISTER_URL, {
        "firstName": firstName,
        "lastName": lastName,
        "userName": userName,
        "email": email,
        "phoneNumber": phoneNumber,
        "password": password,
        "roles": roles
    }, header).then((response) => response.data)
}


// Footer
export function getAllFooters() {
    return axios.get(FOOTER_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllFooterById(id) {
    return axios.get(`${FOOTER_GET_URL}/${id}`, header)
        .then((response) => response.data)
}


// Header
export function getAllHeaders() {
    return axios.get(HEADER_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllHeaderById(id) {
    return axios.get(`${HEADER_GET_URL}/${id}`, header)
        .then((response) => response.data)
}
export function createHeader(title, url, isHeader, isFooter, isShowCase, parentHeaderId) {
    return axios.post(HEADER_CREATE_URL, {
        "title": title,
        "url": url,
        "isHeader": isHeader,
        "isFooter": isFooter,
        "isShowCase": isShowCase,
        "parentHeaderId": parentHeaderId,
        "userId": userId,
    }, header).then((response) => response.data)
}
export function updateHeader(id, title, url, isHeader, isFooter, isShowCase, parentHeaderId) {
    return axios.put(HEADER_UPDATE_URL, {
        "headerID": id,
        "title": title,
        "url": url,
        "isHeader": isHeader,
        "isFooter": isFooter,
        "isShowCase": isShowCase,
        "parentHeaderId": parentHeaderId,
        "userId": userId,
    }, header).then((response) => response.data)
}
export function deleteHeader(id) {
    return axios.delete(`${HEADER_DELETE_URL}/${id}`, header)
        .then((response) => response.data)
}


// Image
export function getAllImages() {
    return axios.get(IMAGE_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllImageById(id) {
    return axios.get(`${IMAGE_GET_URL}/${id}`, header)
        .then((response) => response.data)
}
export function createImage(file, shortDescription, imageGroupID) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('ShortDescription', shortDescription)
    formData.append('ImageGroupID', imageGroupID)
    formData.append('UserId', userId)

    return axios.post(IMAGE_CREATE_URL, formData, formDataHeader)
        .then((response) => response.data)
}
export function deleteImage(id) {
    return axios.delete(`${IMAGE_DELETE_URL}/${id}`, header)
        .then((response) => response.data)
}


// ImageGroup
export function getAllImageGroups() {
    return axios.get(IMAGEGROUP_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllImageGroupById(id) {
    return axios.get(`${IMAGEGROUP_GET_URL}/${id}`, header)
        .then((response) => response.data)
}
export function createImageGroup(title, description) {
    return axios.post(IMAGEGROUP_CREATE_URL, {
        "title": title,
        "description": description,
        "userId": userId,
    }, header).then((response) => response.data)
}
export function updateImageGroup(id, title, description) {
    return axios.put(IMAGEGROUP_UPDATE_URL, {
        "imageGroupID": id,
        "title": title,
        "description": description,
        "userId": userId,
    }, header).then((response) => response.data)
}
export function deleteImageGroup(id) {
    return axios.delete(`${IMAGEGROUP_DELETE_URL}/${id}`, header)
        .then((response) => response.data)
}


// Language
export function getAllLanguages() {
    return axios.get(LANGUAGE_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllLanguageById(id) {
    return axios.get(`${LANGUAGE_GET_URL}/${id}`, header)
        .then((response) => response.data)
}
export function createLanguage(title, languageCode) {
    return axios.post(LANGUAGE_CREATE_URL, {
        "languageTypeId": 0,
        "title": title,
        "languageCode": languageCode
    }, header).then((response) => response.data)
}
export function updateLanguage(id, title, languageCode) {
    return axios.put(LANGUAGE_UPDATE_URL, {
        "languageID": id,
        "languageTypeId": 0,
        "title": title,
        "languageCode": languageCode
    }, header).then((response) => response.data)
}
export function deleteLanguage(id) {
    return axios.delete(`${LANGUAGE_DELETE_URL}/${id}`, header)
        .then((response) => response.data)
}


// Form
export function getAllForms() {
    return axios.get(FORM_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllFormById(id) {
    return axios.get(`${FORM_GET_URL}/${id}`, header)
        .then((response) => response.data)
}
export function createForm(formTitle, shortDescription, pageID) {
    return axios.post(FORM_CREATE_URL, {
        "formTitle": formTitle,
        "shortDescription": shortDescription,
        "pageID": pageID,
        "userId": userId
    }, header).then((response) => response.data)
}
export function updateForm(id, formTitle, shortDescription, pageID) {
    return axios.put(FORM_UPDATE_URL, {
        "formID": id,
        "formTitle": formTitle,
        "shortDescription": shortDescription,
        "pageID": pageID,
        "userId": userId
    }, header).then((response) => response.data)
}
export function deleteForm(id) {
    return axios.delete(`${FORM_DELETE_URL}/${id}`, header)
        .then((response) => response.data)
}

export function createApplication(ToEmail, Subject, Birim, Konum, Isim, Soyisim, SeyahatEngeli,
    Email, CepTelefon, OkulAdi, Bolum, YabanciDil, Konusma, Okuma, Yazma,
    SonIsDeneyimi, Pozisyon, cvFile) {
    const formData = new FormData();
    formData.append('ToEmail', ToEmail);
    formData.append('Subject', Subject);
    formData.append('Birim', Birim);
    formData.append('Konum', Konum);
    formData.append('Isim', Isim);
    formData.append('Soyisim', Soyisim);
    formData.append('SeyahatEngeli', SeyahatEngeli);
    formData.append('Email', Email);
    formData.append('CepTelefon', CepTelefon);
    formData.append('OkulAdi', OkulAdi);
    formData.append('Bolum', Bolum);
    formData.append('YabanciDil', YabanciDil);
    formData.append('Konusma', Konusma);
    formData.append('Okuma', Okuma);
    formData.append('Yazma', Yazma);
    formData.append('SonIsDeneyimi', SonIsDeneyimi);
    formData.append('Pozisyon', Pozisyon);
    formData.append('cvFile', cvFile);

    return axios.post(APPLICATION_URL, formData, formDataHeader)
        .then((response) => response)
};


// FormElement
export function getAllFormElements() {
    return axios.get(FORMELEMENT_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllFormElementByForm(id) {
    return axios.get(`${FORMELEMENT_GETALLBYFORM_URL}/${id}`, header)
        .then((response) => response.data)
}
export function getAllFormElementById(id) {
    return axios.get(`${FORMELEMENT_GET_URL}/${id}`, header)
        .then((response) => response.data)
}
export function createFormElement(formElementType, title, formID) {
    return axios.post(FORMELEMENT_CREATE_URL, {
        "formElementType": formElementType,
        "title": title,
        "formID": formID,
        "userId": userId
    }, header).then((response) => response.data)
}
export function updateFormElement(id, formElementType, title, formID) {
    return axios.put(FORMELEMENT_UPDATE_URL, {
        "formElementID": id,
        "formElementType": formElementType,
        "title": title,
        "formID": formID,
        "userId": userId
    }, header).then((response) => response.data)
}
export function deleteFormElement(id) {
    return axios.delete(`${FORMELEMENT_DELETE_URL}/${id}`, header)
        .then((response) => response.data)
}


// Seo
export function getAllSeos() {
    return axios.get(SEO_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllSeoById(id) {
    return axios.get(`${SEO_GET_URL}/${id}`, header)
        .then((response) => response.data)
}
export function createSeo(seoCode, seoDescription, seoKeywords, seoAuthor, seoTitle, seoTwitter, seoInstagram, seoFacebook) {
    return axios.post(SEO_CREATE_URL, {
        "seoCode": seoCode,
        "seoDescription": seoDescription,
        "seoKeywords": seoKeywords,
        "seoAuthor": seoAuthor,
        "seoTitle": seoTitle,
        "seoTwitter": seoTwitter,
        "seoInstagram": seoInstagram,
        "seoFacebook": seoFacebook,
        "userId": userId
    }, header).then((response) => response.data)
}
export function updateSeo(id, seoCode, seoDescription, seoKeywords, seoAuthor, seoTitle, seoTwitter, seoInstagram, seoFacebook) {
    return axios.put(SEO_UPDATE_URL, {
        "seoID": id,
        "seoCode": seoCode,
        "seoDescription": seoDescription,
        "seoKeywords": seoKeywords,
        "seoAuthor": seoAuthor,
        "seoTitle": seoTitle,
        "seoTwitter": seoTwitter,
        "seoInstagram": seoInstagram,
        "seoFacebook": seoFacebook,
        "userId": userId
    }, header).then((response) => response.data)
}
export function deleteSeo(id) {
    return axios.delete(`${SEO_DELETE_URL}/${id}`, header)
        .then((response) => response.data)
}


// Job
export function getAllJobs() {
    return axios.get(JOB_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllJobById(id) {
    return axios.get(`${JOB_GET_URL}/${id}`, header)
        .then((response) => response.data)
}


// Project
export function getAllProjects() {
    return axios.get(PROJECT_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllProjectById(id) {
    return axios.get(`${PROJECT_GET_URL}/${id}`, header)
        .then((response) => response.data)
}


// FileGroup
export function getAllFileGroups() {
    return axios.get(FILEGROUP_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllFileGroupById(id) {
    return axios.get(`${FILEGROUP_GET_URL}/${id}`, header)
        .then((response) => response.data)
}


// ProjectType
export function getAllProjectTypes() {
    return axios.get(PROJECTTYPE_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllProjectTypeById(id) {
    return axios.get(`${PROJECTTYPE_GET_URL}/${id}`, header)
        .then((response) => response.data)
}


// Country
export function getAllCountrys() {
    return axios.get(COUNTRY_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllCountryById(id) {
    return axios.get(`${COUNTRY_GET_URL}/${id}`, header)
        .then((response) => response.data)
}


// Files
export function getAllFiles() {
    return axios.get(FILES_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllFileByGroup(id) {
    return axios.get(`${FILES_GETALLBYGROUP_URL}/${id}`, header)
        .then((response) => response.data)
}
export function getAllFilesById(id) {
    return axios.get(`${FILES_GET_URL}/${id}`, header)
        .then((response) => response.data)
}


// JobPage
export function getAllJobPages() {
    return axios.get(JOBPAGE_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllJobPageById(id) {
    return axios.get(`${JOBPAGE_GET_URL}/${id}`, header)
        .then((response) => response.data)
}


// FormResponse
export function getAllFormResponses() {
    return axios.get(FORMRESPONSE_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllFormResponseByForm(id) {
    return axios.get(`${FORMRESPONSE_GETALLBYFORM_URL}/${id}`, header)
        .then((response) => response.data)
}
export function getAllFormResponseById(id) {
    return axios.get(`${FORMRESPONSE_GET_URL}/${id}`, header)
        .then((response) => response.data)
}
export function createFormResponse(response, formID) {
    return axios.post(FORMRESPONSE_CREATE_URL, {
        "response": response,
        "formID": formID,
        "userId": userId
    }, header).then((response) => response.data)
}
export function deleteFormResponse(id) {
    return axios.delete(`${FORMRESPONSE_DELETE_URL}/${id}`, header)
        .then((response) => response.data)
}


// Page
export function getAllPages() {
    return axios.get(PAGE_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllPageById(id) {
    return axios.get(`${PAGE_GET_URL}/${id}`, header)
        .then((response) => response.data)
}
export function getAllPageByUrl(url) {
    return axios.get(`${PAGE_GETBYURL_URL}/${url}`, header)
        .then((response) => response.data)
}
export function createPage(file, header, url, imageText, seoId) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('header', header)
    formData.append('url', url)
    formData.append('imageText', imageText)
    formData.append('seoId', seoId)
    formData.append('userId', userId)

    return axios.post(PAGE_CREATE_URL, formData, formDataHeader)
        .then((response) => response.data)
}
export function updatePage(id, file, header, url, imageText, seoId) {
    const formData = new FormData()
    formData.append('pageID', id)
    formData.append('file', file)
    formData.append('header', header)
    formData.append('url', url)
    formData.append('imageText', imageText)
    formData.append('seoId', seoId)
    formData.append('userId', userId)

    return axios.put(PAGE_UPDATE_URL, formData, formDataHeader)
        .then((response) => response.data)
}
export function deletePage(id) {
    return axios.delete(`${PAGE_DELETE_URL}/${id}`, header)
        .then((response) => response.data)
}


// Service
export function getAllServices() {
    return axios.get(SERVICE_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllServiceById(id) {
    return axios.get(`${SERVICE_GET_URL}/${id}`, header)
        .then((response) => response.data)
}
export function createService(title, url, shortDescription, imageGroupID) {
    return axios.post(SERVICE_CREATE_URL, {
        "title": title,
        "url": url,
        "shortDescription": shortDescription,
        "imageGroupID": imageGroupID,
        "userId": userId
    }, header).then((response) => response.data)
}
export function updateService(id, title, url, shortDescription, imageGroupID) {
    return axios.put(SERVICE_UPDATE_URL, {
        "title": title,
        "url": url,
        "shortDescription": shortDescription,
        "imageGroupID": imageGroupID,
        "serviceID": id,
        "userId": userId
    }, header).then((response) => response.data)
}
export function deleteService(id) {
    return axios.delete(`${SERVICE_DELETE_URL}/${id}`, header)
        .then((response) => response.data)
}


// Setting
export function getAllSettings() {
    return axios.get(SETTING_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllSettingById(id) {
    return axios.get(`${SETTING_GET_URL}/${id}`, header)
        .then((response) => response.data)
}
export function updateSetting(id, file, AppName, Url, Address1Title, Address1, Location1, Address2Title, Address2, Location2, Phone1, Phone2, Mail1, Mail2) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('AppName', AppName)
    formData.append('Url', Url)
    formData.append('Address1Title', Address1Title)
    formData.append('Address1', Address1)
    formData.append('Location1', Location1)
    formData.append('Address2Title', Address2Title)
    formData.append('Address2', Address2)
    formData.append('Location2', Location2)
    formData.append('Phone1', Phone1)
    formData.append('Phone2', Phone2)
    formData.append('Mail1', Mail1)
    formData.append('Mail2', Mail2)
    formData.append('SettingID', id)
    formData.append('UserId', userId)

    return axios.put(SETTING_UPDATE_URL, formData, formDataHeader)
        .then((response) => response.data)
}


// ShowCase
export function getAllShowCases() {
    return axios.get(SHOWCASE_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllShowCaseByPage(id) {
    return axios.get(`${SHOWCASE_GETALLBYPAGE_URL}/${id}`, header)
        .then((response) => response.data)
}
export function getAllShowCaseById(id) {
    return axios.get(`${SHOWCASE_GET_URL}/${id}`, header)
        .then((response) => response.data)
}
export function createShowCase(file, Title, ShortDescription, Content, IsColor, PageID) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('Title', Title)
    formData.append('ShortDescription', ShortDescription)
    formData.append('Content', Content)
    formData.append('IsColor', IsColor)
    formData.append('PageID', PageID)
    formData.append('UserId', userId)

    return axios.post(SHOWCASE_CREATE_URL, formData, formDataHeader)
        .then((response) => response.data)
}
export function updateShowCase(id, file, Title, ShortDescription, Content, IsColor, PageID) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('Title', Title)
    formData.append('ShortDescription', ShortDescription)
    formData.append('Content', Content)
    formData.append('IsColor', IsColor)
    formData.append('PageID', PageID)
    formData.append('ShowCaseID', id)
    formData.append('UserId', userId)

    return axios.post(SHOWCASE_UPDATE_URL, formData, formDataHeader)
        .then((response) => response.data)
}
export function deleteShowCase(id) {
    return axios.delete(`${SHOWCASE_DELETE_URL}/${id}`, header)
        .then((response) => response.data)
}


// SocialMedia
export function getAllSocialMedias() {
    return axios.get(SOCIALMEDIA_GETALL_URL, header)
        .then((response) => response.data)
}
export function getAllSocialMediaById(id) {
    return axios.get(`${SOCIALMEDIA_GET_URL}/${id}`, header)
        .then((response) => response.data)
}
export function createSocialMedia(file, Title, Url) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('Title', Title)
    formData.append('Url', Url)
    formData.append('UserId', userId)

    return axios.post(SOCIALMEDIA_CREATE_URL, formData, formDataHeader)
        .then((response) => response.data)
}
export function updateSocialMedia(id, file, Title, Url) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('Title', Title)
    formData.append('Url', Url)
    formData.append('SocialMediaID', id)
    formData.append('UserId', userId)

    return axios.post(SOCIALMEDIA_UPDATE_URL, formData, formDataHeader)
        .then((response) => response.data)
}
export function deleteSocialMedia(id) {
    return axios.delete(`${SOCIALMEDIA_DELETE_URL}/${id}`, header)
        .then((response) => response.data)
}


// User
export function getAllUsers() {
    return axios.get(`${USER_GETALL_URL}?searchTerm=&pageNumber=1&pageSize=50`, header)
        .then((response) => response.data)
}
export function getAllUserById(id) {
    return axios.get(`${USER_GET_URL}/${id}`, header)
        .then((response) => response.data)
}
export function updateUser(id, firstName, lastName, userName, email, phoneNumber) {
    return axios.put(SERVICE_UPDATE_URL, {
        "firstName": firstName,
        "lastName": lastName,
        "userName": userName,
        "email": email,
        "phoneNumber": phoneNumber,
        "userId": id
    }, header).then((response) => response.data)
}
export function changePasswordUser(id, currentPassword, newPassword) {
    return axios.put(`${USER_CHANGEPASSWORD_URL}/${id}`, {
        "currentPassword": currentPassword,
        "newPassword": newPassword
    }, header).then((response) => response.data)
}
export function deleteUser(id) {
    return axios.delete(`${SERVICE_DELETE_URL}/${id}`, header)
        .then((response) => response.data)
}