export const mobilVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        margin: 0,
        // marginTop: 1000,
        width: '100%',
        backgroundColor: '#F3f5f9'
    },
    animate: {
        opacity: 1,
        width: '100%',
        margin: 0,
        marginTop: 0,
        transition: { duration: .4 }
    },
    exit: {
        opacity: 0,
        transition: { duration: .4 }
    }
}

export const projectDetails = {
    initial: {
        opacity: 0,
        padding: 0,
        margin: 0,
        width: '100%',
    },
    animate: {
        opacity: 1,
        width: '100%',
        margin: 0,
        marginTop: 0,
        transition: { duration: .4 }
    },
    exit: {
        opacity: 0,
        transition: { duration: .4 }
    }
}

export const projectVariant2 = {
    initial: {
        opacity: 0,
        padding: 0,
        margin: 0,
        marginTop: 1000,
        width: '100%',
    },
    animate: {
        opacity: 1,
        width: '100%',
        margin: 0,
        marginTop: 0,
        transition: { duration: .4 }
    },
    exit: {
        opacity: 0,
        transition: { duration: .4 }
    }
}

export const projectVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        margin: 0,
        marginTop: 1000,
        width: '100%',
        backgroundColor: '#F3f5f9'
    },
    animate: {
        opacity: 1,
        width: '100%',
        margin: 0,
        marginTop: 0,
        transition: { duration: .4 }
    },
    exit: {
        opacity: 0,
        transition: { duration: .4 }
    }
}

export const headerVariant = {
    initial: {
        opacity: 0,
        marginTop: 100
    },
    animate: {
        opacity: 1,
        marginTop: 0,
        transition: { duration: .4 }
    },
    exit: {
        transition: { duration: .4 }
    }
}

export const mobilSubHeaderVariant = {
    initial: {
        opacity: 0,
        paddingLeft: 100
    },
    animate: {
        opacity: 1,
        paddingLeft: 10,
        transition: { duration: .4 }
    },
    exit: {
        transition: { duration: .4 }
    }
}