import React, { useState } from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { motion } from 'framer-motion'
import { mobilVariant } from '../../components/animation'
import { Button } from 'reactstrap'
import cv from '../../images/cv.png';
import information from '../../images/information-fill.png';
import checkicon from '../../images/check-icon.webp';
import closebutton from '../../images/closebutton.webp';
import { createApplication } from '../../services'
import { phoneCodes } from './phoneCodes'
import { Info, Upload } from 'react-feather'

const ApplicationPage = () => {
    const [lang, setLang] = useState(localStorage.getItem("lang") || "EN");
    const [selectedCode, setSelectedCode] = useState("+90");
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [message, setMessage] = useState("")
    const [fileInfo, setFileInfo] = useState({ name: "", extension: "" });

    const [formData, setFormData] = useState({
        ToEmail: "isealim@mapa.group",
        Subject: "İş Başvurusu",
        Birim: "",
        Konum: "",
        Isim: "",
        Soyisim: "",
        SeyahatEngeli: "",
        Email: "",
        CepTelefon: "",
        OkulAdi: "",
        Bolum: "",
        YabanciDil: "",
        Konusma: "",
        Okuma: "",
        Yazma: "",
        SonIsDeneyimi: "",
        Pozisyon: "",
        cvFile: null,
    });

    const [error, setError] = useState({
        Birim: "",
        Konum: "",
        Isim: "",
        Soyisim: "",
        SeyahatEngeli: "",
        Email: "",
        CepTelefon: "",
        OkulAdi: "",
        Bolum: "",
        YabanciDil: "",
        Konusma: "",
        Okuma: "",
        Yazma: "",
        SonIsDeneyimi: "",
        Pozisyon: "",
        cvFile: "",
    });

    const [status, setStatus] = useState(null);

    const handleChange = (event) => {
        const newErrors = {};
        const { name, value } = event.target;

        if (name === 'Tel') {
            const numericValue = value.replace(/\D/g, '');
            setFormData((prevFormData) => ({
                ...prevFormData,
                CepTelefon: numericValue,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));

        }
        if (name === "Email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (value && !emailRegex.test(value)) {
                newErrors.Email = lang === "TR" ? "Geçerli bir e-posta adresi girin." : "Please enter a valid email address.";
            } else {
                newErrors.Email = "";
            }
        }
        return newErrors;
    };

    // Dosya seçimi için onChange fonksiyonu
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop(); // Dosya uzantısını almak
            setFormData((prevState) => ({
                ...prevState,
                cvFile: file, // Dosyayı formData'ya ekliyoruz
            }));
            setFileInfo({ name: fileName, extension: fileExtension }); // Dosya adı ve uzantısını saklıyoruz
        }
    };
    const addData = async () => {
        console.log(selectedCode)
        console.log(formData);
        const newErrors = {};
        let isValid = true;

        Object.keys(formData).forEach((field) => {
            if (!formData[field]) {
                isValid = false;
                newErrors[field] = lang === "TR" ? "Bu alan zorunludur" : "This field is required";
            }
        });

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formData.Email && !emailRegex.test(formData.Email)) {
            isValid = false;
            newErrors.Email = lang === "TR" ? "Geçerli bir e-posta adresi girin." : "Please enter a valid email address.";
        }

        if (!isValid) {
            setError(newErrors);
            setMessage(lang === "TR" ? "Lütfen tüm zorunlu alanları doldurun." : "Please fill in all required fields.");
            setStatus('failed');
            return;
        }
        setStatus('pending');
        try {
            const response = await createApplication(
                formData.ToEmail, formData.Subject, formData.Birim, formData.Konum,
                formData.Isim, formData.Soyisim, formData.SeyahatEngeli, formData.Email,
                `${selectedCode}${formData.CepTelefon}`, formData.OkulAdi, formData.Bolum, formData.YabanciDil,
                formData.Konusma, formData.Okuma, formData.Yazma, formData.SonIsDeneyimi,
                formData.Pozisyon, formData.cvFile
            );

            setMessage(lang === "TR" ? "Başvurunuz başarıyla gönderilmiştir." : "Application sent successfully.");
            setStatus('success');
            setPopupVisible(true);
        } catch (error) {
            console.log(error.response.data);
            setMessage(lang === "TR" ? "Daha önce başvuruda bulundunuz!" : "You have already applied!");
            setStatus('failed');
        }
    };

    const closePopup = () => {
        setStatus(null);
    };

    const handlePhoneCodeChange = (e) => {
        setSelectedCode(e.target.value);
    };

    return (
        <motion.div {...mobilVariant}>
            <div className='application-page'>
                <Header />
                <div className='application'>
                    <div className='application-background'>
                        <form onSubmit={(e) => e.preventDefault()} className="application-form">
                            <h1>{lang === "TR" ? "Başvuru Formu" : "Application Form"}</h1>
                            <div className='application-content'>
                                <section>
                                    <h2>{lang === "TR" ? "Başvuru Bilgileri" : "Application Information"}</h2>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label className='form-label'>{lang === "TR" ? "Birim" : "Department"} <span className="required">*</span></label>
                                            <select
                                                name="Birim"
                                                value={formData.Birim}
                                                onChange={handleChange}
                                                className="form-control"
                                                placeholder="Seçiniz."
                                                required>
                                                <option value="">{lang === "TR" ? "Seçiniz" : "Select"}</option>
                                                <option value="IK">IK</option>
                                                <option value="AK">AK</option>
                                                <option value="BK">BK</option>
                                            </select>
                                            {error.Birim && <small className="text-danger">{error.Birim}</small>}
                                        </div>
                                        <div className="form-group">
                                            <label className='form-label'>{lang === "TR" ? "Konum" : "Location"} <span className="required">*</span></label>
                                            <select
                                                className="form-control"
                                                name="Konum"
                                                value={formData.Konum}
                                                onChange={handleChange}
                                                required>
                                                <option value="0">{lang === "TR" ? "Seçiniz" : "Select"}</option>
                                                <option value="İstanbul">{lang === "TR" ? "İstanbul" : "Istanbul"}</option>
                                                <option value="Ankara">{lang === "TR" ? "Ankara" : "Ankara"}</option>
                                                <option value="Yurtdışı">{lang === "TR" ? "Yurtdışı" : "Abroad"}</option>
                                            </select>
                                            {error.Konum && <small className="text-danger">{error.Konum}</small>}
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <h2>{lang === "TR" ? "Kişisel Bilgileriniz" : "Contact Information"}</h2>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label className='form-label'>{lang === "TR" ? "İsim" : "Name"} <span className="required">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="Isim"
                                                value={formData.Isim}
                                                onChange={handleChange}
                                                required />
                                            {error.Isim && <small className="text-danger">{error.Isim}</small>}
                                        </div>
                                        <div className="form-group">
                                            <label className='form-label'>{lang === "TR" ? "Soyisim" : "Surname"} <span className="required">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="Soyisim"
                                                value={formData.Soyisim}
                                                onChange={handleChange}
                                                required />
                                            {error.Soyisim && <small className="text-danger">{error.Soyisim}</small>}
                                        </div>
                                        <div className="form-group">
                                            <label className='form-label'>{lang === "TR" ? "Seyahat Engeliniz" : "Travel Restriction"} <span className="required">*</span></label>
                                            <select
                                                className="form-control"
                                                name="SeyahatEngeli"
                                                value={formData.SeyahatEngeli}
                                                onChange={handleChange}
                                                required>
                                                <option value="">{lang === "TR" ? "Seçiniz" : "Select"}</option>
                                                <option value="Var">{lang === "TR" ? "Var" : "Yes"}</option>
                                                <option value="Yok">{lang === "TR" ? "Yok" : "No"}</option>
                                            </select>
                                            {error.SeyahatEngeli && <small className="text-danger">{error.SeyahatEngeli}</small>}
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <h2>{lang === "TR" ? "İletişim Bilgileriniz" : "Contact Information"}</h2>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label className='form-label'>{lang === "TR" ? "E-Posta Adresi" : "E-Mail Address"} <span className="required">*</span></label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="Email"
                                                value={formData.Email}
                                                onChange={handleChange}
                                            />
                                            {error.Email && <small className="text-danger">{error.Email}</small>}
                                        </div>
                                        <div className="form-group phone-group">
                                            <label className='form-label'>{lang === "TR" ? "Cep Telefonu Numaranız" : "Phone Number"} <span className="required">*</span></label>
                                            <div className="phone-input">
                                                <select
                                                    id="phoneCode"
                                                    className="form-control phone-code-select"
                                                    value={selectedCode}
                                                    onChange={(e) => setSelectedCode(e.target.value)}
                                                >
                                                    {phoneCodes.map((item, index) => (
                                                        <option key={index} value={item.dial_code}>
                                                            {item.dial_code}
                                                        </option>
                                                    ))}
                                                </select>
                                                <input
                                                    type="tel"
                                                    id="phone"
                                                    className="form-control phone-number-input"
                                                    name="Tel"
                                                    onInput={(e) => {
                                                        const onlyDigits = e.target.value.replace(/\D/g, '');
                                                        e.target.value = onlyDigits;
                                                        setFormData(prev => ({ ...prev, CepTelefon: `${selectedCode}${onlyDigits}` }));
                                                    }}
                                                    placeholder="" />
                                            </div>

                                            {error.CepTelefon && <small className="text-danger">{error.CepTelefon}</small>}
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <h2>{lang === "TR" ? "Eğitim Bilgileriniz" : "Education Information"}</h2>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label className='form-label'>{lang === "TR" ? "Son Bitirdiği Okul Adı" : "Name of the last school graduated from"} <span className="required">*</span></label>
                                            <input
                                                type="text"
                                                name="OkulAdi"
                                                value={formData.OkulAdi}
                                                onChange={handleChange}
                                                className="form-control" />
                                            {error.OkulAdi && <small className="text-danger">{error.OkulAdi}</small>}
                                        </div>
                                        <div className="form-group">
                                            <label className='form-label'>{lang === "TR" ? "Bölüm" : "Faculty"} <span className="required">*</span></label>
                                            <input
                                                name="Bolum"
                                                value={formData.Bolum}
                                                onChange={handleChange}
                                                type="text" className="form-control" />
                                            {error.Bolum && <small className="text-danger">{error.Bolum}</small>}
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <h2>{lang === "TR" ? "Yabancı Dil" : "Foreign Language"}</h2>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label className='form-label'>{lang === "TR" ? "Yabancı Dil" : "Foreign Language"} <span className="required">*</span></label>
                                            <input
                                                name="YabanciDil"
                                                value={formData.YabanciDil}
                                                onChange={handleChange}
                                                type="text"
                                                id="language"
                                                className="form-control"
                                            />
                                            {error.YabanciDil && <small className="text-danger">{error.YabanciDil}</small>}
                                        </div>
                                        <div className="form-group">
                                            <label className='form-label'>{lang === "TR" ? "Konuşma" : "Speaking"} <span className="required">*</span></label>
                                            <select
                                                name="Konusma"
                                                value={formData.Konusma}
                                                onChange={handleChange}
                                                id="speaking"
                                                className="form-control"
                                                required>
                                                <option value="">{lang === "TR" ? "Seçiniz" : "Select"}</option>
                                                <option value="Zayıf">{lang === "TR" ? "Zayıf" : "Poor"}</option>
                                                <option value="Orta">{lang === "TR" ? "Orta" : "Medium"}</option>
                                                <option value="İyi">{lang === "TR" ? "İyi" : "Good"}</option>
                                                <option value="Çok İyi">{lang === "TR" ? "Çok İyi" : "Very Good"}</option>
                                            </select>
                                            {error.Konusma && <small className="text-danger">{error.Konusma}</small>}
                                        </div>
                                        <div className="form-group">
                                            <label className='form-label'>{lang === "TR" ? "Okuma" : "Reading"} <span className="required">*</span></label>
                                            <select
                                                name="Okuma"
                                                value={formData.Okuma}
                                                onChange={handleChange}
                                                id="reading"
                                                className="form-control"
                                                required>
                                                <option value="">{lang === "TR" ? "Seçiniz" : "Select"}</option>
                                                <option value="Zayıf">{lang === "TR" ? "Zayıf" : "Poor"}</option>
                                                <option value="Orta">{lang === "TR" ? "Orta" : "Medium"}</option>
                                                <option value="İyi">{lang === "TR" ? "İyi" : "Good"}</option>
                                                <option value="Çok İyi">{lang === "TR" ? "Çok İyi" : "Very Good"}</option>
                                            </select>
                                            {error.Okuma && <small className="text-danger">{error.Okuma}</small>}
                                        </div>
                                        <div className="form-group">
                                            <label className='form-label'>{lang === "TR" ? "Yazma" : "Writing"} <span className="required">*</span></label>
                                            <select
                                                id="writing"
                                                className="form-control"
                                                name="Yazma"
                                                value={formData.Yazma}
                                                onChange={handleChange}
                                                required>
                                                <option value="">{lang === "TR" ? "Seçiniz" : "Select"}</option>
                                                <option value="Zayıf">{lang === "TR" ? "Zayıf" : "Poor"}</option>
                                                <option value="Orta">{lang === "TR" ? "Orta" : "Medium"}</option>
                                                <option value="İyi">{lang === "TR" ? "İyi" : "Good"}</option>
                                                <option value="Çok İyi">{lang === "TR" ? "Çok İyi" : "Very Good"}</option>
                                            </select>
                                            {error.Yazma && <small className="text-danger">{error.Yazma}</small>}
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <h2>{lang === "TR" ? "İş Deneyiminiz" : "Work Experience"}</h2>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label className='form-label'>{lang === "TR" ? "Son İş Deneyimi" : "Recent Work Experience"} <span className="required">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="SonIsDeneyimi"
                                                value={formData.SonIsDeneyimi}
                                                onChange={handleChange}
                                                required />
                                            {error.SonIsDeneyimi && <small className="text-danger">{error.SonIsDeneyimi}</small>}
                                        </div>
                                        <div className="form-group">
                                            <label className='form-label'>{lang === "TR" ? "Pozisyon" : "Position"} <span className="required">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="Pozisyon"
                                                value={formData.Pozisyon}
                                                onChange={handleChange}
                                                required />
                                            {error.Pozisyon && <small className="text-danger">{error.Pozisyon}</small>}
                                        </div>
                                        <div className="form-group file-input-wrapper">
                                            <label className='form-label'>{lang === "TR" ? "CV" : "CV"} <span className="required">*</span></label>

                                            <label htmlFor="file-upload" className="custom-file-upload">
                                                <img src={cv} className="file-icon" />
                                                <span>{lang === "TR" ? "Dosya Yükle" : "Upload File"}</span>
                                            </label>
                                            <input
                                                id="file-upload"
                                                name="cvFile"
                                                onChange={handleFileChange}
                                                type="file"
                                                accept=".jpg, .jpeg, .png, .pdf" />
                                            {!fileInfo.name && (
                                                <span className='information'>
                                                    <img src={information} alt="info" />
                                                    {lang === "TR" ? "PDF, PNG veya JPG" : "PDF, PNG or JPG"}
                                                </span>
                                            )}
                                            {!fileInfo.name && error.cvFile && (
                                                <small className="text-danger">{error.cvFile}</small>
                                            )}
                                            {fileInfo.name && (
                                                <span className='information'>
                                                    Seçildi: {fileInfo.name}
                                                </span>
                                            )}

                                        </div>
                                    </div>
                                </section>
                                <section >
                                    <div className='d-flex justify-content-center'>
                                        <Button type="button" onClick={() => addData()} className='application-button'>
                                            {lang === "TR" ? "Gönder" : "Submit"}
                                        </Button>
                                    </div>
                                </section>
                            </div>
                        </form>
                    </div>
                    {status && (
                        <div className="popup-overlay">
                            <div className="popup">
                                <div className='close'>
                                    <button className="close-button" onClick={closePopup}><img src={closebutton} /></button>
                                </div>
                                <div className="popup-content">
                                    {status === 'pending' && (
                                        <div>
                                            <i className="loading-icon">⏳</i>
                                            <h2>{lang === "TR" ? "Gönderiliyor..." : "Sending..."}</h2>
                                            <p>{lang === "TR" ? "Lütfen bekleyin." : "Please wait a moment."}</p>
                                        </div>
                                    )}
                                    {status === 'success' && (
                                        <div>
                                            <img src={checkicon} className="check-icon" />
                                            <h2>{lang === "TR" ? "Başvurunuz başarıyla iletilmiştir." : "Your application has been submitted successfully."}</h2>
                                            <p>{lang === "TR" ? "İlginiz için teşekkür ederiz." : "Thank you for your attention."}</p>
                                        </div>
                                    )}
                                    {status === 'failed' && (
                                        <div>
                                            <Info className='text-danger' size={80} />
                                            <h2 className='text-danger mt-4'>{lang === "TR" ? "Başvuru başarısız oldu!" : "Application Disapproved!"}</h2>
                                            <p>{message}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {isPopupVisible && (
                        <div className="popup-overlay">
                            <div className="popup">
                                <div className='close'>
                                    <button className="close-button" onClick={closePopup}><img src={closebutton} /></button>
                                </div>
                                <div className="popup-content">
                                    <img src={checkicon} className="check-icon" />
                                    <h2>{lang === "TR" ? "Başvurunuz başarıyla iletilmiştir." : "Your application has been submitted successfully."}</h2>
                                    <p>{lang === "TR" ? "İlginiz için teşekkür ederiz." : "Thank you for your attention."}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <Footer />
            </div>
        </motion.div>
    )
}

export default ApplicationPage
